import React from 'react';
import unicorn from './unicorn.svg';
import rainbow from './rainbow.svg';
import tree from './tree.svg';

const RainbowUnicorn = () => {

  const unicorns = [];
  const trees = [];

  // Create an array of 10 unicorns with random positions, rotations, speeds, and sizes
  for (let i = 0; i < 9; i++) {
    const randomTop = Math.floor(Math.random() * 101) + '%';
    const randomLeft = Math.floor(Math.random() * 101) + '%';
    const randomRotation = Math.floor(Math.random() * 361) + 'deg';
    const randomDuration = (Math.floor(Math.random() * 10) + 5) + 's';
    const randomSize = (Math.floor(Math.random() * 30) + 10) + '%';

    unicorns.push(
      <div key={'unicorn' + i} style={{ position: 'absolute', top: randomTop, left: randomLeft, width: randomSize, height: randomSize, animation: `rotate ${randomDuration} linear infinite`, transform: `rotate(${randomRotation})` }}>
        <img src={unicorn} alt="unicorn" style={{ width: '100%', height: '100%' }} />
      </div>
    );
  }

  // Create an array of 20 trees with random positions, rotations, speeds, and sizes
  for (let i = 0; i < 3; i++) {
    const randomTop = Math.floor(Math.random() * 101) + '%';
    const randomLeft = Math.floor(Math.random() * 101) + '%';
    const randomRotation = Math.floor(Math.random() * 361) + 'deg';
    const randomDuration = (Math.floor(Math.random() * 10) + 5) + 's';
    const randomSize = (Math.floor(Math.random() * 30) + 10) + '%';

    trees.push(
      <div key={'tree' + i} style={{ position: 'absolute', top: randomTop, left: randomLeft, width: randomSize, height: randomSize, animation: `rotate ${randomDuration} linear infinite`, transform: `rotate(${randomRotation})` }}>
        <img src={tree} alt="tree" style={{ width: '100%', height: '100%' }} />
      </div>
    );
  }

  return (
    <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: '50%', height: '50%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <img src={rainbow} alt="rainbow" style={{ width: '100%', height: '100%' }} />
      {unicorns}
      {trees}
      <style>{`
        @keyframes rotate {
          from {
            transform: rotate(0deg);
          }
          to {
            transform: rotate(360deg);
          }
        }
      `}</style>
    </div>
  );
};

export default RainbowUnicorn;