import React, { useState } from 'react';
import Confetti from 'react-confetti';
import { makeStyles } from '@material-ui/core/styles';


import {
  Button,
  Card,
  CardContent,
  FormControl,
  InputLabel,
  Input,
  MenuItem,
  Select,
  Typography,
  Snackbar,
} from '@material-ui/core';


const CloudKey = () => {
  const [selectedOption, setSelectedOption] = useState('');
  const [confetti, setConfetti] = useState(false);
  const [options, setOptions] = useState([]);
  const [generateKeysVisible, setGenerateKeysVisible] = useState(false);
  const [continueClicked, setContinueClicked] = useState(false);
  const [popupVisible, setPopupVisible] = useState(false);
  const [popupContent, setPopupContent] = useState('');
  const [popupDisabled, setPopupDisabled] = useState(false);
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };

  const continueButtonClick = (password) => {
    // Simulate an API call
    fetch(`https://legacy.activity-availability.clubspeed.com/CloudServiceKeys/Venues/${password}`, {
      method: 'GET',
      headers: {
        'Accept': 'text/plain'
      }
    })
      .then(response => response.json())
      .then(data => {

        data.unshift({ venueName: 'Select an Option', clientId: -1 });

        // Set the options for the dropdown list
        setOptions(data.map(venue => ({
          label: venue.venueName,
          value: venue.clientId
        })));
        // Show the "Generate Keys" button
        setGenerateKeysVisible(true);
        // Hide the password textbox and the continue button
        setContinueClicked(true);

        // Set the selected option to the value of the first option in the list
      if (data.length > 0) {
        setSelectedOption(data[0].clientId);
      }
      })
      .catch(error => {
        console.error(error);
        setErrorMessage('The password is incorrect. Please try again!');
      });
  };

  const handleGenerateKeysClick = () => {

    if (selectedOption == -1){
      setErrorMessage('Please select an option before proceeding.');
      return;
    }

    fetch('https://legacy.activity-availability.clubspeed.com/CloudServiceKeys/CloudKeys', {
      method: 'POST',
      headers: {
        'Accept': 'text/plain',
        'Content-Type': 'application/json-patch+json'
      },
      body: JSON.stringify({
        key: password,
        clientId: selectedOption
      })
    })
      .then(response => response.json())
      .then(data => {
        // Create a textarea element and set its value to the API results
        const textarea = document.createElement('textarea');
        textarea.value = data.script;

        // Append the textarea to the document body
        document.body.appendChild(textarea);

        // Select the contents of the textarea
        textarea.select();

        // Copy the selected text to the clipboard
        document.execCommand('copy');

        // Remove the textarea from the document body
        document.body.removeChild(textarea);

        // Show the popup with the API results
        setPopupVisible(true);
        setPopupContent(data.script);
        setPopupDisabled(false);
        // Launch the confetti
       
      })
      .catch(error => {
        console.error(error);
        setErrorMessage('Error generating keys. Please try again.');
      });
  };

  const handlePopupClose = () => {
    setPopupVisible(false);
    setConfetti(true);
  };
  const handleCopyButtonClick = () => {
    const textarea = document.createElement('textarea');
    textarea.value = popupContent;
    document.body.appendChild(textarea);
    textarea.select();
    document.execCommand('copy');
    document.body.removeChild(textarea);
  };

  const handleConfettiComplete = () => {
    setConfetti(false);
  };

  const handleSnackbarClose = () => {
    setErrorMessage('');
  };

  return (
<Card style={{ backgroundColor: 'white', width: 400, margin: '0 auto', marginTop: 50, zIndex: 1 }}>
      <CardContent>
        <Typography variant="h5" component="h2" align="center" gutterBottom>
          Clubspeed Cloud Key Generator
        </Typography>
        {!continueClicked && (
          <div>
            <FormControl variant="outlined" fullWidth style={{ marginBottom: 20 }}>
              <InputLabel htmlFor="password-box">Password</InputLabel>
              <Input
                id="password-box"
                type="password"
                value={password}
                onChange={(event) => setPassword(event.target.value)}
              />
            </FormControl>
          </div>
        )}
        {continueClicked ? null : (
          <Button variant="contained" color="primary" fullWidth onClick={() => continueButtonClick(password)}>
            Continue
          </Button>
        )}
       {options.length > 0 && (
        <FormControl variant="outlined" fullWidth>
  <Select
    labelId="select-label"
    value={selectedOption}
    onChange={handleOptionChange}
    inputProps={{
      name: 'user',
      id: 'user-select',
    }}
  >

    {options.map(option => (
      <MenuItem key={option.value} value={option.value} label={option.label}>
        {option.label}
      </MenuItem>
    ))}
  </Select>
</FormControl>
)}
        {generateKeysVisible && (
          <Button variant="contained" color="primary" fullWidth style={{ marginTop: 10 }} onClick={handleGenerateKeysClick}>
            Provision Keys
          </Button>
        )}

        {popupVisible && (
          <div style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', backgroundColor: 'rgba(0, 0, 0, 0.5)', zIndex: 9999 }        }>
            <div style={{ backgroundColor: 'white', padding: 20, borderRadius: 10, position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', maxWidth: '80vw', maxHeight: '80vh', overflow: 'auto' }}>
              <Typography variant="h6" gutterBottom>You are a rockstar. Don't ever forget it. Copy this script and run it at the venue:</Typography>
              <FormControl variant="outlined" fullWidth style={{ marginBottom: 20 }}>
                <Input
                  id="popup-content-box"
                  multiline
                  rows={10}
                  value={popupContent}
                  disabled={true}
                />
              </FormControl>
              <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: 10 }}>
                <Button variant="contained" color="primary" disabled={popupDisabled} onClick={handleCopyButtonClick}>Copy to Clipboard</Button>
                <Button variant="contained" color="primary" onClick={handlePopupClose} style={{ marginLeft: 10 }}>Close</Button>
              </div>
            </div>
          </div>
        )}

        {confetti && (
          <Confetti
            width={window.innerWidth}
            height={window.innerHeight}
            onConfettiComplete={handleConfettiComplete}
          />
        )}

        <Snackbar open={errorMessage}
        message={errorMessage}
          onClose={handleSnackbarClose}
          autoHideDuration={6000}
        />
      </CardContent>
    </Card>
  );
};

export default CloudKey;