import React, { useState } from 'react';
import {
  Button,
  Card,
  CardContent,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
  CssBaseline,
  makeStyles
} from '@material-ui/core';
import CloudKey from './CloudKey';
import RainbowUnicorn from './RainbowUnicorn';
import unicorn from './unicorn.svg';

const useStyles = makeStyles((theme) => ({
  container: {
    height: '100vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    overflow: 'hidden',
    position: 'relative',
  },
  backgroundLayer: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    background: 'linear-gradient(to right, red, orange, yellow, green, blue, indigo, violet, red)',
    backgroundSize: '1600% 1600%',
    animation: '$moveBackground 7s ease infinite',
  },
  unicornLayer: {
    position: 'absolute',
    top: `${Math.random() * 100}%`,
    left: `${Math.random() * 100}%`,
    transform: 'translate(-50%, -50%)',
    width: '30%',
    height: '30%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    animation: '$spinUnicorns 12s linear infinite, $shootRainbow 2s linear infinite',
  },
  unicorn: {
    width: '100%',
    height: '100%',
  },
  '@keyframes moveBackground': {
    '0%': {
      backgroundPosition: '0 0',
    },
    '50%': {
      backgroundPosition: '100% 0',
    },
    '100%': {
      backgroundPosition: '0 0',
    },
  },
  '@keyframes spinUnicorns': {
    '0%': {
      transform: 'rotate(0deg)',
    },
    '100%': {
      transform: 'rotate(360deg)',
    },
  },
  '@keyframes shootRainbow': {
    '0%': {
      transform: 'rotate(0deg)',
    },
    '50%': {
      transform: 'rotate(90deg)',
    },
    '100%': {
      transform: 'rotate(0deg)',
    },
  },
}));

const handleToggleRainbowUnicorn = (prevState, setShowRainbowUnicorn) => {
  setShowRainbowUnicorn(!prevState);
};

function App() {
  const classes = useStyles();
  const [showRainbowUnicorn, setShowRainbowUnicorn] = useState(false);

  return (
    <>
    <CssBaseline />
      <div className={classes.container}>
        {showRainbowUnicorn && <div className={classes.backgroundLayer}></div>}
        {showRainbowUnicorn && <RainbowUnicorn />}
        {showRainbowUnicorn && (
          <div className={classes.unicornLayer}>
            <img src={unicorn} alt="unicorn" className={classes.unicorn} />
          </div>
        )}
        <CloudKey />
        <Button
          style={{ position: 'absolute', width: 350, top: '5%', right: '5%', zIndex: 0 }}
          variant="contained"
          color="primary"
          fullWidth
          onClick={() => handleToggleRainbowUnicorn(showRainbowUnicorn, setShowRainbowUnicorn)}
        >
          {showRainbowUnicorn ? '😔 mode' : '🦄 mode'}
        </Button>
      </div>
    </>
  );
}

export default App;